import type { AddToCartPayload } from 'analytics/events/eec/addToCart/addToCart'
import { DEFAULT_LOOK_ID } from 'product/constants/constants'

import { DEFAULT_SELLER } from '../../constants/constants'
import {
	type CartDispatch,
	type CartStateContextType,
} from '../../context/CartProvider'
import type { FetcherPropsType } from '../../hooks/useCartFetcher'
import type { ErrorItem } from '../../hooks/useErrorManagement'
import type { ItemType, SummaryType } from '../../services/graphql.types'
import { addItem } from '../../services/mutations'
import { findByUser } from '../../services/queries'
import type { Item } from '../../types/ControllerType'
import { checkItemStock } from '../checkItemStock'
import { getMngItems } from '../getMngItems'
import { updateTotalItems } from './updateTotalItems'

export interface CartDataType {
	findTotalItemsByUser: {
		totalItems: number
		merged: boolean
	}
}

export interface AddType {
	showShoppingCart: boolean
	cartState: CartStateContextType
	fetcher: <FData, FVariables>(
		data?: FetcherPropsType<FVariables>
	) => Promise<FData>
	globalMutate: Function
	dispatch: CartDispatch
	addErrorManagement: (errors: ErrorItem[] | ErrorItem) => void
	addItemNotification: Function
	isDesktop: boolean
	sendAddToCart: (params: AddToCartPayload) => void
	countryISO: string
}

export interface ProductType {
	collectionId?: string
	colorId: string
	customColor?: string
	customPosition?: string
	customSize?: string
	customType?: string
	lookId: string
	productId: string
	needleWork?: string
	sizeId: string
	seller: string
	lastUnits: boolean
	layoutView?: string
}

interface AddItemType {
	items: ItemType[]
	summary: SummaryType
	totalItems: number
	metadata: {
		limitsExceeded: boolean
	}
}

const fetchAndUpdate = async ({
	fetcher,
	globalMutate,
	item,
	dispatch,
}: {
	fetcher: <FData, FVariables>(
		data?: FetcherPropsType<FVariables>
	) => Promise<FData>
	globalMutate: Function
	item: Item
	dispatch: CartDispatch
}) => {
	const response = await fetcher<{ addItem: AddItemType }, { item: Item }>({
		query: addItem,
		variables: { item },
	})

	updateTotalItems(response.addItem.totalItems, globalMutate, dispatch)

	globalMutate(
		{ query: findByUser },
		{ findByUser: response.addItem },
		{ revalidate: false }
	)

	return response
}

const getLookId = (lookId: string) => lookId?.toString() || DEFAULT_LOOK_ID

const setPreventClose = ({
	dispatch,
	showPreview,
	showShoppingCart,
}: {
	dispatch: CartDispatch
	showPreview: string | boolean
	showShoppingCart: boolean
}) => {
	if (showPreview && !showShoppingCart) {
		dispatch({ preventClose: true })
	}
}

const getDispatch = ({
	dispatch,
	showPreview,
	showShoppingCart,
}: {
	dispatch: CartDispatch
	showPreview: string | boolean
	showShoppingCart: boolean
}) => {
	if (!showPreview && !showShoppingCart) {
		dispatch({ showPreview: 'auto' })
	}
}

export const add =
	({
		showShoppingCart,
		cartState,
		fetcher,
		globalMutate,
		isDesktop,
		dispatch,
		addErrorManagement,
		addItemNotification,
		sendAddToCart,
		countryISO,
	}: AddType): ((
		product: ProductType,
		location: string,
		productListId?: string
	) => void) =>
	async (product, location, productListId): Promise<void> => {
		const { showPreview } = cartState

		setPreventClose({ dispatch, showPreview, showShoppingCart })

		const isNotDesktop = !isDesktop
		try {
			const {
				collectionId,
				colorId,
				customColor,
				customPosition,
				customSize,
				customType,
				lookId,
				productId,
				needleWork,
				sizeId,
				seller = DEFAULT_SELLER,
			} = product
			const customized = {
				collectionId: collectionId?.toString(),
				customColor,
				customPosition,
				customSize,
				customType,
				needleWork,
			}

			const item: Item = {
				colorId,
				productId,
				seller,
				sizeId,
				look: getLookId(lookId),
			}

			if (
				customized &&
				Object.values(customized).some((attribute) => !!attribute)
			) {
				item.customized = customized
			}

			await checkItemStock(item, countryISO)

			const response = await fetchAndUpdate({
				globalMutate,
				fetcher,
				item,
				dispatch,
			})

			sendAddToCart({
				item: { ...item, lastUnits: product.lastUnits, quantity: 1, index: 1 },
				mngItems: getMngItems(response.addItem.items),
				listId: productListId,
				location,
			})

			getDispatch({ dispatch, showPreview, showShoppingCart })

			if (isNotDesktop || showShoppingCart) {
				addItemNotification()
			}
			sessionStorage.removeItem('reloadsCount')
		} catch (errors) {
			addErrorManagement(
				Array.isArray(errors) ? (errors as ErrorItem[]) : (errors as ErrorItem)
			)
		}
	}
