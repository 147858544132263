'use client'

import { useParams, useRouter } from 'next/navigation'

/**
 * Hook that returns a function to navigate to a given path.
 */
export function useNavigation(): {
	navigate: typeof navigate
	replace: typeof replace
	prefetch: typeof prefetch
} {
	const router = useRouter()
	const { country, language } = useParams()

	const navigate = (to: string, disableSPA = true): void => {
		const startsWithSlash = to.startsWith('/')
		const url = startsWithSlash ? `/${country}/${language}${to}` : to

		if (disableSPA) {
			window.location.assign(url)
		} else {
			startsWithSlash ? router.push(url) : window.location.assign(url)
		}
	}

	const replace = (to: string, disableSPA = true): void => {
		const startsWithSlash = to.startsWith('/')
		const url = startsWithSlash ? `/${country}/${language}${to}` : to

		if (disableSPA) {
			window.location.replace(url)
		} else {
			startsWithSlash ? router.replace(url) : window.location.replace(url)
		}
	}

	const prefetch = (to: string): void => {
		to.startsWith('/')
			? router.prefetch(`/${country}/${language}${to}`)
			: fetch(to, { next: { revalidate: 0 } })
	}

	return { navigate, replace, prefetch }
}
