export enum SnackbarStatus {
	ERROR = 'error',
	INFO = 'info',
}

export enum SnackbarPosition {
	TOP = 'top',
	BOTTOM = 'bottom',
}

export const DEFAULT_SNACKBAR_DISTANCE_TOP = '1.5rem'
const DEFAULT_SNACKBAR_DISTANCE_BOTTOM = '1rem'

export const DEFAULT_SNACKBAR_DISTANCE: Record<SnackbarPosition, string> = {
	[SnackbarPosition.TOP]: DEFAULT_SNACKBAR_DISTANCE_TOP,
	[SnackbarPosition.BOTTOM]: DEFAULT_SNACKBAR_DISTANCE_BOTTOM,
}

const MARGIN_PROPERTY_TOP = 'right'
const MARGIN_PROPERTY_BOTTOM = 'bottom'

export const MARGIN_PROPERTY: Record<SnackbarPosition, 'right' | 'bottom'> = {
	[SnackbarPosition.TOP]: MARGIN_PROPERTY_TOP,
	[SnackbarPosition.BOTTOM]: MARGIN_PROPERTY_BOTTOM,
}
