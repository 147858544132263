import { graphql } from 'codegen/src/gateway'

export const findByUser = graphql(`
	query FindByUser($channel: String!, $orderBy: ShoppingCartOrderByInput) {
		findByUser(channel: $channel, orderBy: $orderBy) {
			... on ShoppingCartDto {
				totalItems
				deliveries {
					order
					items {
						...FragmentItemsFields
					}
				}
				items {
					...FragmentItemsFields
				}
				summary {
					...FragmentSummaryDtoFields
				}
				metadata {
					limitsExceeded
				}
			}
		}
	}
`).toString()

export const getBagItems = graphql(`
	query FindTotalItemsByUser($channel: String!) {
		findTotalItemsByUser(channel: $channel) {
			totalItems
		}
	}
`).toString()
