'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { useCanSendAnalytics } from 'analytics/hooks/useCanSendAnalytics/useCanSendAnalytics'
import { useEECEvents } from 'analytics/hooks/useEECEvents/useEECEvents'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { getClientProductPriceUrl } from 'product/services/productPrices/getClientPriceUrl'
import type { ProductColor } from 'product/types'
import type { ProductPrices } from 'product/types/Prices'
import type { MasterData } from 'types/masterData'

import { BagErrorTypes, GenericEvents } from '../analytics/events'
import { SHOPPING_CART_LOCATION } from '../constants/constants'
import type { ItemDto } from '../services/graphql.types'
import type { CartProduct } from '../types/CartProduct'

interface GetItems {
	cartItems: ItemDto[] | undefined
	countryISO: MasterData['country']['countryISO']
	channel: MasterData['channel']
}

function getItems({ cartItems, countryISO, channel }: GetItems) {
	return Promise.all(
		!cartItems
			? []
			: cartItems.map(async (item) => {
					const pricesResponse = await fetch(
						getClientProductPriceUrl({
							countryISO,
							channel,
							productId: item.productId,
						}),
						{ next: { revalidate: 0 } }
					)
					const prices = (await pricesResponse.json()) as Record<
						ProductColor['id'],
						ProductPrices
					>

					return {
						productId: item.productId,
						colorId: item.colorId,
						lookId: item.lookId,
						currentPrice: prices[item.colorId].price,
						originalPrice:
							prices[item.colorId].previousPrices?.originalShop ||
							prices[item.colorId].price,
						index: 1,
						quantity: item.quantity,
						sizeId: item.sizeId,
					}
				})
	)
}

export const useAnalytics = () => {
	const {
		country: { countryISO },
		channel,
	} = useMasterData()
	const { sendViewCart, sendBeginCheckout, sendSelectItem } = useEECEvents()
	const { canSendAnalytics } = useCanSendAnalytics()

	return {
		sendViewCartEECEvent: async (
			bagItemNumber: number,
			location: SHOPPING_CART_LOCATION,
			cartItems: ItemDto[] | undefined
		): Promise<void> => {
			if (canSendAnalytics) {
				const items = await getItems({
					cartItems,
					countryISO,
					channel,
				})
				sendViewCart({
					bagItemNumber,
					location,
					items,
				})
			}
		},
		sendBeginCheckoutEECEvent: async (
			location: SHOPPING_CART_LOCATION,
			cartItems: ItemDto[] | undefined
		): Promise<void> => {
			const items = await getItems({
				cartItems,
				countryISO,
				channel,
			})
			sendBeginCheckout({
				location,
				items,
			})
		},
		sendSelectItemEECEvent: async (
			location: SHOPPING_CART_LOCATION,
			cartItem: CartProduct
		): Promise<void> => {
			const [item] = await getItems({
				cartItems: [cartItem],
				channel,
				countryISO,
			})
			sendSelectItem({
				location,
				item,
			})
		},
		sendBagUpdateEvent: () => {
			sendGenericEvent(GenericEvents.BAG_UPDATE)
		},
		sendBagErrorAddItemEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.ADD_ITEM,
			})
		},
		sendBagErrorRemoveItemEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.REMOVE_ITEM,
			})
		},
		sendBagErrorTotalItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.TOTAL_ITEMS,
			})
		},
		sendBagErrorPreviewItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.PREVIEW_ITEMS,
			})
		},
		sendBagErrorPageItemsEvent: () => {
			sendGenericEvent(GenericEvents.BAG_ERROR, {
				type: BagErrorTypes.PAGE_ITEMS,
			})
		},
		sendBagStockTotalErrorEvent: (
			action: 'show' | 'click_CTA' | 'click_screen'
		) => {
			sendGenericEvent(GenericEvents.STOCK_TOTAL_ERROR, {
				action,
			})
		},
		sendBagStockPartialErrorEvent: (
			action: 'show' | 'click_beginCheckout' | 'click_returnBag'
		) => {
			sendGenericEvent(GenericEvents.STOCK_PARTIAL_ERROR, {
				action,
			})
		},
	}
}
