import { type CartDispatch } from './CartProvider'

export const closeLimitWarningModal = (dispatch: CartDispatch): void => {
	dispatch({ openedLimitWarningModal: false, preventClose: true })
}

export const openLimitWarningModal = (dispatch: CartDispatch): void => {
	dispatch({ openedLimitWarningModal: true })
}

export const showLimitWarningBanner = (dispatch: CartDispatch): void => {
	dispatch({ visibleLimitWarningBanner: true })
}

export const hideLimitWarningBanner = (dispatch: CartDispatch): void => {
	dispatch({ visibleLimitWarningBanner: false })
}
