import { CacheClass } from 'cache/cache'
import type { CacheClassProps } from 'cache/types/types'
import { CACHE_KEY, EXPIRED } from 'favorite/constants/constants'

export let cacheInstance: CacheClass | null

export const favoritesCache = (logged = false, token?: string) => {
	if (cacheInstance) {
		return cacheInstance
	}

	if (token) {
		const payload = {
			cacheKey: CACHE_KEY,
		} as CacheClassProps
		if (logged) {
			payload.maxDiffTimestamp = EXPIRED
			payload.token = token
		}
		cacheInstance = new CacheClass(payload)
		return cacheInstance
	}

	return null
}
