import { PRODUCT_STOCK_CLIENT_SERVICE_URL } from 'product/constants/constants'
import type { Stock } from 'product/types/Stock'
import { fetcher } from 'services/rest/fetcher'
import { env } from 'utils/envManager'

import type { Item } from '../types/ControllerType'
import noStockException from './exceptions'

export const checkItemStock = async (
	item: Item,
	countryISO: string
): Promise<void> => {
	if (!item.colorId || !item.sizeId) {
		return
	}
	const basePath = `${PRODUCT_STOCK_CLIENT_SERVICE_URL}${item.productId}`
	const url = new URL(basePath, env.NEXT_PUBLIC_SITE_BASE_URL)
	const searchParams = new URLSearchParams({
		countryIso: countryISO,
		channelId: env.NEXT_PUBLIC_CHANNEL,
	})
	url.search = searchParams.toString()

	const stock: Stock = await fetcher<Stock>(url.href)

	if (!stock.colors[item.colorId]?.sizes[item.sizeId]?.available) {
		throw noStockException(item.productId)
	}
}
