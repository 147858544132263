import { type FetcherPropsType } from '../../hooks/useCartFetcher'
import type { ItemType, SummaryType } from '../../services/graphql.types'
import { mergeWithGuest } from '../../services/mutations'
import { findByUser, getBagItems } from '../../services/queries'

interface MergeType {
	fetcher: <FData, FVariables>(
		data?: FetcherPropsType<FVariables>
	) => Promise<FData>
	globalMutate: Function
}

interface MergeWithGuestType {
	merged: boolean
	items: ItemType
	summary: SummaryType
	totalItems: number
	metadata: {
		limitsExceeded: boolean
	}
}

export const merge = async ({
	fetcher,
	globalMutate,
}: MergeType): Promise<void> => {
	try {
		const response = await fetcher<
			{ mergeFrom: MergeWithGuestType },
			undefined
		>({
			query: mergeWithGuest,
		})

		globalMutate(
			{ query: findByUser },
			{ findByUser: response.mergeFrom },
			{ revalidate: false }
		)

		globalMutate(
			{ query: getBagItems },
			{ findTotalItemsByUser: response.mergeFrom },
			{ revalidate: false }
		)
	} catch (error) {
		console.error(error)
	}
}
