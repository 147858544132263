import type { CountryConfiguration } from 'types/countryConfiguration'

const REPLACEMENT_KEY = '%@'
const DEFAULT_FACTOR = 1.0

interface FormattedPrices {
	price: string
	additionalPrices?: string[]
}

/**
 * Formats the amount of a price without currency symbol
 */
function formatAmount({
	amount,
	decimals,
	locale,
}: {
	amount: number
	decimals: number
	locale: string
}) {
	const formatter = new Intl.NumberFormat(locale, {
		minimumFractionDigits: decimals,
		maximumFractionDigits: decimals,
	})
	return formatter.format(amount)
}

/**
 * Applies a factor to a price
 */
function factorizePrice(amount: number, factor: number) {
	return amount * factor
}

/**
 * Adds the currency symbol to a formatted amount with format string
 */
function addCurrencySymbol(format: string, amount: string): string {
	return format.replace(REPLACEMENT_KEY, amount)
}

function formatPrice({
	amount,
	decimals,
	format,
	locale,
}: {
	amount: number
	decimals: number
	format: string
	locale: string
}): string {
	const formattedAmount = formatAmount({ amount, decimals, locale })
	return addCurrencySymbol(format, formattedAmount)
}

export const conversion = (
	price: number,
	country: CountryConfiguration,
	numDecimals?: number
): FormattedPrices => {
	const { locale, currency, additionalCurrencies } = country
	const { decimals, format } = currency

	const convertedPrices: FormattedPrices = {
		price: formatPrice({
			amount: price,
			decimals: numDecimals ?? decimals,
			locale,
			format,
		}),
	}

	if (additionalCurrencies) {
		convertedPrices.additionalPrices = additionalCurrencies.map(
			(additionalCurrency) => {
				// For additional currencies, we need to factorize the price
				const factorizedPrice = factorizePrice(
					price,
					additionalCurrency.factor || DEFAULT_FACTOR
				)
				return formatPrice({
					amount: factorizedPrice,
					decimals: additionalCurrency.decimals,
					format: additionalCurrency.format,
					locale,
				})
			}
		)
	}
	return convertedPrices
}
