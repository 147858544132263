'use client'

import {
	getFromLocalStorage,
	setToLocalStorage,
} from 'utils/webStorage/localStorage'

export const CURRENT_LOGGED_STATUS_KEY = 'favorites.isLogged'

/**
 * This method is used to check if the user has just logged in or out and if so, we need to
 * sync the favorites array or empty it, respectively
 */
export const hasLoggedChanged = (isLogged: boolean): boolean => {
	const wasLoggedBefore = JSON.parse(
		getFromLocalStorage<string>(CURRENT_LOGGED_STATUS_KEY) ?? 'false'
	)

	return wasLoggedBefore !== isLogged
}

export const setIsLogged = (isLogged: boolean) => {
	setToLocalStorage(CURRENT_LOGGED_STATUS_KEY, String(isLogged))
}
