import type { AddFavorites } from 'favorite/types/types'

import { ENDPOINT } from './constants'

export const addFavorites = ({
	products,
	countryISO,
	languageISO,
	token,
}: AddFavorites): Promise<Response> => {
	const body = JSON.stringify({
		country: countryISO,
		language: languageISO,
		products,
	})

	return fetch(`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${ENDPOINT}/add`, {
		method: 'POST',
		headers: {
			Authorization: `Bearer ${token}`,
		},
		next: { revalidate: 0 },
		body,
	})
}
