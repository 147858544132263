import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import { type CartDispatch, useCartDispatch } from '../context/CartProvider'
import { openLimitWarningModal } from '../context/LimitsWarning'
import { useAnalytics } from '../hooks/useAnalytics'
import { type PurchaseType } from '../services/graphql.types'
import { useNotifications } from './useNotifications'

export interface ErrorItem {
	message: string
	path?: string[]
	extensions?: Extensions
}

interface Extensions {
	exception?: Exception
	oneItemShoppingCartOnly?: boolean
	shoppingCart?: PurchaseType | null
}

interface Exception {
	path: string[]
	message: string
}

interface UseErrorManagement {
	addErrorManagement: (errors: ErrorItem | ErrorItem[]) => void
}

interface AddErrorHandlersType {
	error: ErrorItem
	dispatch: CartDispatch
	environment?: string
}

const addErrorHandlers = (
	itemWithoutStockNotification: () => void
): {
	[key: string]: (param: AddErrorHandlersType) => void
} => ({
	MaxNumberOfItems: ({ dispatch }: AddErrorHandlersType) => {
		// A limit was reached (max number of items or amount limit)
		openLimitWarningModal(dispatch)
	},
	AmountLimit: ({ dispatch }: AddErrorHandlersType) => {
		// A limit was reached (max number of items or amount limit)
		openLimitWarningModal(dispatch)
	},
	ItemWithoutStock: () => {
		// The item is out of stock
		itemWithoutStockNotification()
	},
	SessionExpired: () => {
		// The session has expired
		window.location.reload()
	},
	addItem: ({ error, environment }: AddErrorHandlersType) => {
		// Adding an item to the shopping cart failed
		// TODO: UX needs to address this error case.
		if (environment !== 'pro') {
			const { exception } = error.extensions || {}
			// eslint-disable-next-line no-alert
			window.alert(`
				IMPORTANT: This error message only appears on non-production environments, as it needs to be addressed by UX.
				Meanwhile, this will serve for debugging purposes.
				Error: ${exception?.message}
				At path: ${exception?.path}
			`)
		}
	},
})

/**
 * Hook that handles the errors of the shopping cart.
 */
export const useErrorManagement = (): UseErrorManagement => {
	const dispatch = useCartDispatch()
	const { addItemNotificationError, itemWithoutStockNotification } =
		useNotifications()
	const { sendBagErrorAddItemEvent } = useAnalytics()

	const { isDesktop, environment } = useMasterData()

	const getPath = (error: ErrorItem): string[] => {
		const { path } = error.extensions?.exception ?? error
		return path || ['']
	}

	const errorHandlers = addErrorHandlers(itemWithoutStockNotification)

	/**
	 * Manage errors that can occur during the add to cart process.
	 */
	const addErrorManagement = (errors: ErrorItem | ErrorItem[]): void => {
		if (!Array.isArray(errors)) {
			// Generic error for mobile when errors is not an array
			addItemNotificationError()
			sendBagErrorAddItemEvent()
			console.error(errors)
			return
		}

		errors.forEach((error) => {
			const type = Object.keys(errorHandlers).find((errorKey) =>
				getPath(error).includes(errorKey)
			)
			const handler = type ? errorHandlers[type] : null
			if (handler) {
				handler({ error, dispatch, environment })
			} else if (!isDesktop) {
				// Generic error for mobile when errors is an array
				addItemNotificationError()
			} else {
				// No handling required for desktop with no handler
			}
		})
		// eslint-disable-next-line no-console
		console.error(errors)
	}

	return { addErrorManagement }
}
