import type { GetAllFavorites } from 'favorite/types/types'

import { ENDPOINT } from './constants'

export const getAllFavorites = ({
	token,
}: GetAllFavorites): Promise<Response> => {
	return fetch(`${process.env.NEXT_PUBLIC_SITE_BASE_URL}${ENDPOINT}/all`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		next: { revalidate: 0 },
	})
}
