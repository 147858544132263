const noStockException = (productId: string) => {
	return [
		{
			message: `Item ${productId} without stock in default warehouse`,
			path: ['ItemWithoutStock'],
			extensions: {
				classification: 'DataFetchingException',
				parameters: {
					stock: false,
				},
			},
		},
	]
}

export default noStockException
