'use client'

import { type RefObject, useEffect, useState } from 'react'

export interface IntersectionObserverOptions extends IntersectionObserverInit {
	/** Only values on px or percentage are allowed */
	rootMargin?: string
	/** Only first intersection is captured */
	freezeOnceVisible?: boolean
}

export function useIntersectionObserver(
	elementRef: RefObject<Element>,
	{
		threshold = 0,
		root = null,
		rootMargin = '0%',
		freezeOnceVisible = false,
	}: IntersectionObserverOptions
): IntersectionObserverEntry | undefined {
	const [entry, setEntry] = useState<IntersectionObserverEntry>()

	const frozen = entry?.isIntersecting && freezeOnceVisible

	const updateEntry = ([newEntry]: IntersectionObserverEntry[]): void => {
		setEntry(newEntry)
	}

	useEffect(() => {
		const node = elementRef?.current // DOM Ref
		const hasIOSupport = !!window.IntersectionObserver

		if (!hasIOSupport || frozen || !node) {
			return () => {}
		}

		const observerParams = { threshold, root, rootMargin }
		const observer = new IntersectionObserver(updateEntry, observerParams)

		observer.observe(node)

		return () => observer.disconnect()
	}, [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen])

	return entry
}
