import { getAllFavorites } from 'favorite/services/getAllFavorites'

import type { FavoriteProduct } from '../../types/types'

export const getFavorites = async (
	token?: string
): Promise<FavoriteProduct[]> => {
	const response = await getAllFavorites({ token })

	if (!response.ok) {
		throw response
	}
	return response.json()
}
