import { getBagItems } from '../../services/queries'

/**
 * Updates the number of items in the cart
 */
export const updateTotalItems = (
	totalItems: number,
	globalMutate: Function,
	dispatch: Function
): void => {
	dispatch({
		totalItems,
	})

	globalMutate(
		{ query: getBagItems },
		{ findTotalItemsByUser: { totalItems } },
		{ revalidate: false }
	)
}
